import React, {useEffect, useState} from 'react';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import {fetchPolicy} from "../../utils/api";
import {Link} from "react-router-dom";
import DOMPurify from "dompurify";

const Policy = (props) => {
    const { policyType } = props;
    const [contentLoading, setContentLoading] = useState(true);
    const [content, setContent] = useState(undefined);
    const [contentVersion, setContentVersion] = useState(undefined);
    const [contentEffectiveDate, setContentEffectiveDate] = useState(undefined);
    const [error, setError] = useState(undefined);

    useEffect(() => {
        (async () => {
            try {
                const response = await fetchPolicy(policyType);
                const { effective_date, html_content, version } = response.data;
                setContentEffectiveDate(effective_date);
                setContent(DOMPurify.sanitize(html_content));
                setContentVersion(version);
            } catch (e) {
                setError(e)
            } finally {
                setContentLoading(false);
            }
        })()
    }, [policyType])

    const renderPolicyContent = () => {
      return <div dangerouslySetInnerHTML={{__html: content}}/>;
    };

    const renderLoading = () => {
        return <p className={"text-center"}>Latest policy content is loading...</p>;
    };

    const renderError = () => {
        return <p className={"text-center"}>
            Something went wrong. Please <Link to='/contact/'>contact us</Link> for questions about
            this policy.
        </p>;
    };

    return (
        <article id="single">
            <Header/>
            <section className="single privacy">
                <div className="wrap">
                    {contentLoading && renderLoading()}
                    {contentVersion && <div>[Version {contentVersion}]</div>}
                    {
                        contentEffectiveDate && <div style={{paddingBottom: "20px"}}>
                            [EFFECTIVE DATE: {contentEffectiveDate}]
                        </div>
                    }
                    {content && renderPolicyContent()}
                    {error && renderError()}
                </div>
            </section>
            <Footer/>
        </article>
    );
}

export default Policy;
