import axios from "axios";

const getBaseApiUrl = () => {
    switch (window.location.hostname) {
        case "localhost":
            return "https://dev-api.aptihealth.com/Prod/v";
        case "www-staging.aptihealth.com":
            return "https://release-api.aptihealth.com/Prod/v";
        default:
            return "https://prod-api.aptihealth.com/Prod/v";
    }
};

export const fetchPolicy = (policy_type) => {
    const url = `${getBaseApiUrl()}/v1/public/get_policy?policy_type=${policy_type}`
    return axios
        .get(url)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            console.error("Error fetching policy content:", error);
        });
};
